import type {ExtendedPromise, OttoNexus, QueuableFunction} from "@otto-ec/global-resources/nexus";
import {apps} from "../index.js";

export type NativeAppEvent = {
    /**
 *
 */
    name: string;
    /**
 *
 */
    timestamp: Date;
}

export type Global = {
    retrieveNativeAppEvents: QueuableFunction<RetrieveNativeAppEvents>;
}

export type RetrieveNativeAppEvents =
/**
 *
 */
    () => ExtendedPromise<NativeAppEvent[]>;

/**
 *
 */
export const global: OttoNexus["apps"]["global"] = apps.global;

export const {
    /**
 *
 */
    retrieveNativeAppEvents
} = global;