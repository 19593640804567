import { appType, AppType } from "../helper";
import { global, type NativeAppEvent, type RetrieveNativeAppEvents } from "./index";
import type { FunctionImplementation } from "@otto-ec/global-resources/nexus";
import type { NativeAppEventAndroidDTO } from "./nativeAppEventAndroidDTO";

export const retrieveNativeAppEvents = function retrieveNativeAppEvents() {
    const fallbackValue: NativeAppEvent[] = []

    if(appType() == AppType.ANDROID) {
        const events = window.o_apps_android?.retrieveNativeAppEvents?.()
    
        if (!events) return fallbackValue

        const nativeAppEventAndroidDTO = JSON.parse(events) as NativeAppEventAndroidDTO[]

        return nativeAppEventAndroidDTO.map((event) => ({
            ...event,
            timestamp: new Date(event.timestamp)
        }));
    
    } else if(appType() == AppType.IOS) {
        return window.webkit?.messageHandlers?.retrieveNativeAppEvents?.postMessage({}) ?? fallbackValue
    }
    return fallbackValue;
} satisfies FunctionImplementation<RetrieveNativeAppEvents>

global.assignNamespace({retrieveNativeAppEvents})